let config = {
  host: "",
  port: "",
  baseUrl: "",
  type: process.env.NODE_ENV,
};

if (config.type === "development") {
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  config.baseUrl = "https://api.mingyuanyyw.com/";
  config.port = "";
  config.host = "";
} else if (config.type === "production") {
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  config.baseUrl = "https://api.mingyuanyyw.com/";
  config.port = "";
  config.host = "";
} else if (config.type === "test") {
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  config.baseUrl = "https://api.mingyuanyyw.com/";
  config.port = "";
  config.host = "";
}
export default config;
