<template>
  <div id="app">
    <router-view />
  </div>
  
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { LgetItem, LreItem } from "./utils/storage";
export default {
  name: "App",
  created() {
    this.$store.dispatch("getBadge");
  },
  updated() {
    if (LgetItem("token")) {
      this.$api("account.getisLogin").catch(() => {
        LreItem("token");
        LreItem("info");
        this.$bus.$emit("logout"); //登录被挤
        this.$store.dispatch("getBadge");
      });
    }
  },
};
</script>

<style lang="less">
@import "assets/css/base.less";
@import "assets/css/global.less";
</style>
