export default {
  // 用户登录
  getLogin: {
    method: "post",
    url: "/session/login/save",
  },
  // 广告图获取
  getAD: {
    method: "post",
    url: "/get/adv/list",
  },
  // 经营类型
  getType: {
    method: "post",
    url: "/membership/type/index",
  },
  // 地区
  getCity: {
    method: "post",
    url: "/address/city/index",
  },
  // 验证码
  getCode: {
    method: "post",
    url: "/captcha/captcha/save",
  },
  // 忘记密码
  findPass: {
    method: "post",
    url: "/user/resetpassword/save",
  },
  // 注册
  register: {
    method: "post",
    url: "/user/user/save",
  },
  // 协议
  agreement: {
    method: "post",
    url: "/app/registration/agreement",
  },

  // 请求授权登录二维码
  wx_login_url: {
    method: "post",
    url: "/wechat/web/login",
  },
  
  // 上传code执行登录
  wx_login_code: {
    method: "post",
    url: "/wechat/web/code",
  },

};
